<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>

<style lang="scss">
body {
  margin: 0;
}
</style>
