import { createRouter, createWebHashHistory } from 'vue-router'
import Global from '@/global'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/DashboardView.vue'),
    redirect: '/dashboard'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/IndexView.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue')
      },
      
      // 权限管理
      {
        path: '/auth/menu',
        name: 'auth.menu',
        component: () => import('../views/auth/AuthMenu.vue')
      },
      {
        path: '/auth/auth',
        name: 'auth.auth',
        component: () => import('../views/auth/AuthView.vue')
      },
      {
        path: '/auth/role',
        name: 'auth.role',
        component: () => import('../views/auth/AuthRole.vue')
      },
      {
        path: '/auth/manage',
        name: 'auth.manage',
        component: () => import('../views/auth/AuthManage.vue')
      },
      {
        path: '/auth/operate',
        name: 'auth.operate',
        component: () => import('../views/auth/OperateData.vue')
      },
      
      //撰写统计
      {
        path: '/stat/write-count',
        name: 'stat.writeCount',
        component: () => import('../views/stat/WriteCount.vue')
      },

      
      //AI关键词列表
      {
        path: '/ai/ai-keyword-list',
        name: 'ai.aiKeywordList',
        component: () => import('../views/ai/AiIndex.vue')
      },
      //AI文章编辑
      {
        path: '/ai/ai-edit-manage',
        name: 'ai.aiEditManage',
        component: () => import('../views/ai/AiEditManage.vue')
      },
      //AI文章审核
      {
        path: '/ai/ai-article-audit',
        name: 'ai.aiArticleAudit',
        component: () => import('../views/ai/AiArticleAudit.vue')
      },
      

      //文章改写
      {
        path: '/collect/article-list',
        name: 'collect.article-list',
        component: () => import('../views/collect-article/CollectIndex.vue')
      },
 

      //文章改写
      // {
      //   path: '/test-stream',
      //   name: 'test-stream',
      //   component: () => import('../views/TestStream.vue')
      // },

      //AI问答关键词列表
      {
        path: '/ai-question/ai-keyword-list',
        name: 'ai-question.aiKeywordList',
        component: () => import('../views/ai-question/AiIndex.vue')
      },
      //AI问答编辑
      {
        path: '/ai-question/ai-edit-manage',
        name: 'ai-question.aiEditManage',
        component: () => import('../views/ai-question/AiEditManage.vue')
      },
      //AI问答审核
      {
        path: '/ai-question/ai-question-audit',
        name: 'ai-question.aiArticleAudit',
        component: () => import('../views/ai-question/AiQuestionAudit.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断当前是否在登录状态
  if (Global.getToken()) {
    if (to.path.indexOf("/login") != -1) {
      next('/');

      return false;
    }
  } else {
    if (to.path.indexOf("/login") == -1) {
      next("/login");

      return false;
    }
  }

  next();
});

export default router
