import moment from 'moment'


const appName = '宇宙系统'
// const apiHost = 'http://universe-admin.cc/api'
const apiHost = 'https://yuzhou.sfga.cn/api'


const accessTokenStorageKey = 'universe_token'
const accessTokenExpiresStorageKey = 'universe_token_expires_at'
const userInfoStorageKey = 'universe_current_user'
const userInfoExpiresStorageKey = 'universe_current_user_expires_at'

const getToken = () => {
  let token = localStorage.getItem(accessTokenStorageKey)
  let tokenExpires = localStorage.getItem(accessTokenExpiresStorageKey)

  if (token && tokenExpires > new Date().getTime()) {
    return token
  }

  return false
}

const setToken = token => {
  localStorage.setItem(accessTokenStorageKey, token.token)
  localStorage.setItem(accessTokenExpiresStorageKey, new Date().getTime() + token.expires_in * 60 * 1000)

  return true
}

const getUserInfo = () => {
  let userInfo = JSON.parse(localStorage.getItem(userInfoStorageKey))

  if (userInfo) {
    return userInfo
  }

  return false
}

const setUserInfo = info => {
  localStorage.setItem(userInfoStorageKey, JSON.stringify(info))
  localStorage.setItem(userInfoExpiresStorageKey, new Date().getTime() + 24 * 60 * 60 * 1000)

  return true
}

const isUserInfoExpired = () => {
  let userExpired = localStorage.getItem(userInfoExpiresStorageKey)

  return ! (userExpired && (userExpired > new Date().getTime()))
}

const formatDate = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return ''
  }

  return moment(date).locale('zh-cn').format(format)
}

const strIs = (pattern, value) => {
  let patterns = pattern instanceof Array ? pattern : [pattern];

  value = String(value);

  if (!value) {
      return false;
  }

  for (let p of patterns) {
    p = String(p);

    if (p == value) {
        return true;
    }

    p = pregQuote(p);
    p = p.replace('\\*', '.*');

    if (RegExp('^' + p + '$').test(value)) {
      return true;
    }
  }

  return false;
}

const pregQuote = (str, delimiter) => {
  return (str + '').replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'), '\\$&');
}

const can = (auth) => {
  let userInfo = getUserInfo()
  let auths = auth instanceof Array ? auth : [auth]

  let permissions = userInfo.all_permissions

  if (permissions) {
    for (let permission of permissions) {
      for (let a of auths) {
        if (strIs(permission.name, a)) {
          return true
        }
      }
    }
  }

  return false
}

export default {
  appName,
  apiHost,
  getToken,
  setToken,
  getUserInfo,
  setUserInfo,
  isUserInfoExpired,
  formatDate,
  can
}
